import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Claim No.', 'claimNo', true, 'desc', 'left'),
  new TableColumn('Display Name', 'displayName', true, 'desc', 'left'),
  new TableColumn('Task Status', 'taskStatus', true, 'desc', 'left'),
  new TableColumn('Task Type', 'taskType', true, 'desc', 'left'),
  new TableColumn('Job Status', 'businessStatusText', true, 'desc', 'left'),
  new TableColumn('Quote(s)', 'quoteNumbers', true, 'desc', 'left'),
  new TableColumn('Creation Date', 'creationDate', true, 'desc', 'left'),
  new TableColumn('Synced Date', 'syncedDate', true, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
